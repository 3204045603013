import axios from 'axios'
import api from '@/utils/endpoints'
import dotenv from 'dotenv'

dotenv.config()

const headers = {
  Accept: 'application/json',
}

export const login = params => new Promise((resolve, reject) => {
  axios
    .post(`${process.env.VUE_APP_API_URL}/${api.auth.login}`, params, { headers })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
})

export const register = params => new Promise((resolve, reject) => {
  axios
    .post(`${process.env.VUE_APP_API_URL}/${api.auth.register}`, params, { headers })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
})

export const getUserByToken = () => {
  // eslint-disable-next-line no-shadow
  const headers = {
    Accept: 'application/json',
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  }
  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.VUE_APP_API_URL}/${api.auth.getUserByToken}`, { headers })
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}
