/* eslint-disable no-shadow */
import * as companyService from '@/services/company'

const state = {
  user: null,
  role: null,
  companyId: null,
  company: null,
}

const mutations = {
  get_user(state, value) {
    state.user = value
    state.role = value.role_name
    state.companyId = value.company_id
    localStorage.setItem('role', value.role_name)
    localStorage.setItem('companyId', value.company_id)
    localStorage.setItem('custom_roles', value.custom_roles)
  },

  set_company(state, value) {
    state.company = value
  },
}

const actions = {
  async get_company_action({ commit, state }) {
    const company = await companyService.edit(state.companyId)
    commit('set_company', company)
  },
}

const getters = {}

export default {
  state,
  actions,
  mutations,
  getters,
}
