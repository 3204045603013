/* eslint-disable no-shadow */
const state = {
  isLoading: false,
  companyUrl: null,
}

const actions = {
  async get_companyUrl_action(context) {
    const url = window.location.origin
    context.commit('set_companyUrl', url)
  },
}

const mutations = {
  set_loading_action(state, value) {
    state.isLoading = value
  },
  set_companyUrl(state, value) {
    state.companyUrl = value
  },
}

const getters = {}

export default {
  state,
  actions,
  mutations,
  getters,
}
