/* eslint-disable no-shadow */
import axios from 'axios'
import api from '@/utils/endpoints'
import dotenv from 'dotenv'

dotenv.config()

export const getCompanies = () => new Promise((resolve, reject) => {
  const headers = {
    Accept: 'application/json',
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  }
  axios
    .get(`${process.env.VUE_APP_API_URL}/${api.company.get}`, { headers })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
})

export const create = params => new Promise((resolve, reject) => {
  const headers = {
    Accept: 'application/json',
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  }
  axios
    .post(`${process.env.VUE_APP_API_URL}/${api.company.create}`, params, { headers })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
})

export const edit = id => new Promise((resolve, reject) => {
  const headers = {
    Accept: 'application/json',
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  }
  axios
    .get(`${process.env.VUE_APP_API_URL}/${api.company.edit}/${id}`, { headers })
    .then(res => {
      if (res.data.success) {
        resolve(res.data)
      }
    })
    .catch(err => {
      reject(err)
    })
})

export const update = params => new Promise((resolve, reject) => {
  const headers = {
    Accept: 'application/json',
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  }
  axios
    .post(`${process.env.VUE_APP_API_URL}/${api.company.update}`, params, { headers })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
})

export const deleteCompany = id => new Promise((resolve, reject) => {
  const headers = {
    Accept: 'application/json',
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  }
  axios
    .get(`${process.env.VUE_APP_API_URL}/${api.company.delete}/${id}`, { headers })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
})

export const getCompanyByCompany = () => new Promise((resolve, reject) => {
  const headers = {
    Accept: 'application/json',
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  }
  axios
    .get(`${process.env.VUE_APP_API_URL}/${api.company.getCompanyByCompany}`, { headers })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
})
